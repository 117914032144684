<template>
  <div class="aspect-square select-none overflow-hidden rounded-full bg-green">
    <div class="relative h-full w-full">
      <div class="animate-[spin_30s_linear_infinite]">
        <slot name="text" />
      </div>
      <div class="absolute inset-0 flex items-center justify-center p-[24%]">
        <slot name="illustration" />
      </div>
    </div>
  </div>
</template>
